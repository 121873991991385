import { createSharedComposable } from '@vueuse/core'
import type { VehiclePlateData, VehicleGenericInformations, From } from '~/types/interfaces'

const _useDashboard = () => {
    const route = useRoute()
    const isMultipleVehicleSelectOpen = ref(false)
    const isSearchVehicleOpen = ref(false)
    const isMobileNavBarOpen = ref(false) 
    const searchVehicleFrom = ref<From>('dashboard')
    const vehiclesPlateData = ref<VehiclePlateData[]>([])
    const vehiclesGenericData = ref<VehicleGenericInformations[]>([])

    watch(() => route.fullPath, () => {
        isMultipleVehicleSelectOpen.value = false
        isSearchVehicleOpen.value = false
        isMobileNavBarOpen.value = false
        searchVehicleFrom.value = 'dashboard'
    })

    return {
        isMultipleVehicleSelectOpen,
        isSearchVehicleOpen,
        isMobileNavBarOpen,
        vehiclesPlateData,
        vehiclesGenericData,
        searchVehicleFrom
    }
}

export const useDashboard = createSharedComposable(_useDashboard)